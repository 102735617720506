type TEntryKeys<TYPE, K = keyof TYPE> = [K, any];

export function cloneInitialStoreValues<Contexts extends object>(
  context: Contexts
): TEntryKeys<Contexts>[] {
  return Object.entries(context).reduce(
    (acc: TEntryKeys<typeof context>[], entrie) => {
      const [key, value] = entrie as TEntryKeys<typeof context>;

      switch (key) {
        case 'rootStore':
          return acc;
        case 'initialValues':
          return acc;
        default:
          if (typeof context[key] === 'function') return acc;
      }

      if (value === undefined) return acc;

      const entrieItem: [keyof typeof context, any] = [
        key,
        typeof value === 'object' ? JSON.parse(JSON.stringify(value)) : value,
      ];

      acc.push(entrieItem);

      return acc;
    },
    []
  );
}
