import { clearStore } from './utils/clearStore';
import { cloneInitialStoreValues } from './utils/cloneInitialStoreValues';
import { setKeysValuesInstance } from './utils/setKeysValuesInstance';
import { setKeyValueInstance } from './utils/setKeyValue';

export class StoreUtils<Context extends object> {
  context: Context;
  private initialValues;
  setKeyValue;
  clearStore;
  setKeysValues;

  constructor(context: Context) {
    this.context = context;
    this.initialValues = cloneInitialStoreValues(context);

    this.setKeyValue = setKeyValueInstance(this.context);
    this.clearStore = clearStore.bind(this.context, this.initialValues);
    this.setKeysValues = setKeysValuesInstance(this.context);
  }
}
